.images-row-pregames {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    width: 80%;
}

.images-row-pregames img {
    width: 45%;
    height: auto;
}

.project-link-pregames {
    text-decoration: none;
    color: #00FFFF;
    font-size: 14px;
    margin-top: 5px;
}

.project-link-container-pregames {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}