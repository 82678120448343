.card {
    border: 2px solid; /* Use the borderColor prop */
    border-radius: 10px; /* Rounded edges */
    padding: 15px;
    margin: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .card-title {
    margin: 0;
    padding: 0;
    font-weight: bold;
  }
  
  .card-description {
    margin: 10px 0;
    padding: 0;
    width: 210px;
    white-space: normal;
  }
  